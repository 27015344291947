import swal from 'sweetalert2'

export default {
    async fire() {
        return swal.fire({
            icon: "warning",
            title: "Attention cette action est irréversible",
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonText: "Annuler",
            confirmButtonText: "Continuer",
        })
    }
}